export default function DemoCreds({setEmailAddress, setPassword}) {

    const preFillLogins = [{
        "role": "Admin Login",
        "email": "demo.user@example.com",
        "password": "123456"
    }]

    const preFillLogin = (item) => {
        setEmailAddress( item.email )
        setPassword( item.password )
    }

    return <table className="table table-bordered mt-3">
                <thead>
                    <tr>
                        <th> Demo Login Details</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                { preFillLogins.map( (item,index) =>
                    <tr key={"demo-"+index}>
                        <td> { item.role }
                            <div>{ item.email }</div>
                            <div>{ item.password }</div>
                        </td>
                        <td>
                            <button className="btn" onClick={ (e) => preFillLogin( item ) }> <i className="fa fa-copy"></i> </button>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
}